import actions from "../../actions";
import { combineReducers } from "redux";
const { JOINUS_REQ, JOINUS_REQ_DONE } = actions;

export const joinReq = (state = false, action) => {
  switch (action.type) {
    case JOINUS_REQ:
      return true;
    case JOINUS_REQ_DONE:
      return false;
    default:
      return state;
  }
};

export const joinReqDone = (state = null, action) => {
  switch (action.type) {
    case JOINUS_REQ_DONE:
      return action.data;
    default:
      return state;
  }
};

export default combineReducers({
  joinReq,
  joinReqDone,
});
