import { CssBaseline, Paper } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { StylesProvider, ThemeProvider, jssPreset } from "@mui/styles";
import { create } from "jss";
import rtl from "jss-rtl";
import {
  default as React,
  Suspense,
  memo,
  useEffect,
  useRef,
  useState,
  lazy,
  Navigate,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Routes, useLocation, useNavigate, Route } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import actions from "../redux/actions";
// import ExitForm from "../components/shared/exitForm/ExitForm";
import { makeTheme } from "../styles/globalTheme/makeTheme";
import { useParams } from "react-router-dom";
const ExitForm = lazy(() => import("../components/shared/exitForm/ExitForm"));
const Home = lazy(() => import("../pages/home/Home"));
const Header = lazy(() => import("../components/header/Header"));
const Footer = lazy(() => import("../components/footer/Footer"));
const JoinUs = lazy(() => import("../pages/joinUs/JoinUs"));
const BasicPage = lazy(() => import("../pages/basicPage/BasicPage"));
const CategoriesPage = lazy(() =>
  import("../pages/categoriesPage/CategoriesPage")
);
const Gallery = lazy(() => import("../pages/gallery/Gallery"));
const Error404 = lazy(() => import("../pages/error/Error404"));
const ContactUs = lazy(() => import("../pages/contactUs/ContactUs"));

function Routing() {
  const { basicTheme } = useSelector((state) => state.theme_reducer);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  window.navigateTo = navigate;
  window.dispatch = dispatch;
  window.currentLocation = location;
  window.navigateToDefault = () => window.navigateTo("/home");
  const componentRef = useRef();
  const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
  const theme = makeTheme(basicTheme);
  const materialTheme = createTheme(theme);
  const { getData } = actions;
  const reducer = useSelector((state) => state);
  const [menuItems, setMenuItems] = useState([]);
  const [configurations, setConfigurations] = useState([]);
  const [headerMenuItems, setHeaderMenuItems] = useState([]);
  const [footerMenuItems, setFooterMenuItems] = useState([]);
  const [quickLinks, setQuickLinks] = useState([]);
  const [toggleSpinner, setToggleSpinner] = useState(false);
  let lang = "ar";
  const { alias } = useParams();

  useEffect(() => {
    let sort = "menuItem";

    dispatch(getData({ sort }));
  }, []);

  useEffect(() => {
    dispatch(
      getData({
        sort: "configuration",
      })
    );
  }, []);

  useEffect(() => {
    if (
      reducer?.crud?.getAll ||
      reducer?.crud?.getData ||
      reducer?.joinUs?.joinReq ||
      reducer?.crud?.getByAlias ||
      reducer?.filterPosts?.filterPosts ||
      reducer?.contactUs?.contactNew
    )
      setToggleSpinner(true);
    else setToggleSpinner(false);
  }, [reducer]);

  useEffect(() => {
    if (reducer?.crud?.dataReturned.menuItems) {
      setMenuItems(reducer?.crud?.dataReturned?.menuItems);
    }
  }, [reducer.crud?.dataReturned.menuItems]);

  useEffect(() => {
    if (reducer?.crud?.dataReturned?.configurations) {
      setConfigurations(reducer?.crud?.dataReturned?.configurations);
    }
  }, [reducer.crud?.dataReturned?.configurations]);

  useEffect(() => {
    if (menuItems && menuItems?.length > 0) {
      setHeaderMenuItems(menuItems.filter((item) => item?.id === 1));
      setFooterMenuItems(menuItems.filter((item) => item?.id === 2));
      setQuickLinks(menuItems.filter((item) => item?.id === 3));
    }
  }, [menuItems]);

  return (
    <StylesProvider jss={jss}>
      <ThemeProvider theme={materialTheme}>
        <CssBaseline />

        <Paper
          ref={componentRef}
          display="flex"
          flex-direction="column"
          dir={"rtl"}
          id="full-page"
          style={{
            background: materialTheme.palette.background.default,
            position: "absolute",
            minHeight: "100vh",
            height: "100%",

            width: "100%",
            boxShadow: "none",
            display: "flex",
            flexDirection: "column",
            filter:
              theme && theme?.globals?.type === "blind"
                ? "grayscale(100%)"
                : "inherit",
          }}
        >
          <Suspense fallback={<h1></h1>}>
            <Header
              menuItems={headerMenuItems.flatMap((item) => item.childrens)}
            />
            <Routes>
              <Route path={`/:language/home`} element={<Home />} />
              <Route path={`/:language/join-us`} element={<JoinUs />} />
              <Route path={`/:language/page/:alias`} element={<BasicPage />} />
              <Route
                path={`/:language/section/:alias`}
                element={<CategoriesPage />}
              />
              <Route path={`/:language/:type/:alias`} element={<BasicPage />} />
              <Route
                path={`/:language/section/:alias`}
                element={<BasicPage />}
              />
              <Route path={`/:language/gallery`} element={<Gallery />} />
              <Route
                path={`/:language/about-us`}
                element={<BasicPage about />}
              />
              <Route path={`/:language/contact-us`} element={<ContactUs />} />
              <Route
                path={`/:language/not-found`}
                element={<Error404 message={t("ERROR.LANGUAGEMASSAGE")} />}
              />
              <Route
                path={`/:language/404`}
                element={<Error404 message={t("ERROR.MESSAGE")} />}
              />
              <Route
                path={"*"}
                element={<Error404 message={t("ERROR.MESSAGE")} />}
              />
            </Routes>
            <Footer
              menuItems={footerMenuItems.flatMap((item) => item?.childrens)}
              configurations={configurations}
            />
            <ExitForm />
            {toggleSpinner ? (
              <div
                style={{
                  display: "flex",
                  top: 0,
                  bottom: 0,
                  zIndex: 6500,
                  backdropFilter: "blur(2px)",
                  right: 0,
                  backgroundColor: "#ffffff1a",
                  left: 0,
                  position: "fixed",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <BeatLoader
                  color={theme.palette.secondary.main}
                  loading={true}
                  size={15}
                  speedMultiplier={1}
                />
              </div>
            ) : null}
          </Suspense>
        </Paper>
      </ThemeProvider>
    </StylesProvider>
  );
}

export default memo(Routing);
