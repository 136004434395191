export const makeTheme = ({ currentTheme, isRTL, isDark }) => {
  return {
    direction: "rtl",
    palette: {
      background: {
        default: "#fff",
      },
      primary: {
        main: currentTheme.elements.colors.black,
      },
      secondary: {
        main: currentTheme.elements.colors.golden,
      },
    },
    name: currentTheme.name,
    globals: { ...currentTheme.elements, direction: "rtl" },
  };
};
export const darkTheme = ({ currentTheme, isRTL, isDark }) => {
  return {
    direction: "rtl",

    palette: {
      type: "dark",
      background: {
        default: "#0A1929",
      },
      primary: {
        main: currentTheme.elements.colors.primary,
      },
      secondary: {
        main: currentTheme.elements.colors.golden,
      },
      black: {
        main: "#fff",
      },
      gray: {
        main: "#fff",
      },
    },
    globals: {
      ...currentTheme.elements,
      colors: {
        ...currentTheme.elements.colors,
        black: "#2E2E2E",
        golden: "#AE9971",
        gold: "#BE912D",
      },
    },
  };
};
export const blindTheme = ({ currentTheme, isRTL }) => {
  return {
    direction: "rtl",
    palette: {
      type: "blind",
      background: {
        default: "#fff",
      },
      primary: {
        main: currentTheme.elements.colors.primary,
      },
      secondary: {
        main: currentTheme.elements.colors.golden,
      },
      black: {
        main: currentTheme.elements.colors.black,
      },
      gray: {
        main: currentTheme.elements.colors.gray,
      },
    },
    globals: { ...currentTheme.elements, direction: "rtl" },
  };
};
