import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import { buildCanonicalUrl } from "../components/shared/utils";
import { setDirection } from "../redux/actionCreators/theme";
import Routing from "./Routing";

function Index() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const [spinner, setSpinner] = useState(false);
  let currentLanguage = "ar";
  const { pathname: currentUrl } = useLocation();

  useEffect(() => {
    (async () => {
      let { canonicalUrl, canonicalLanguage } = await buildCanonicalUrl(
        currentUrl,
        currentLanguage
      );
      if (currentLanguage !== canonicalLanguage) {
        dispatch(setDirection({ isRTL: true }));
        i18n.changeLanguage(currentLanguage);
      }

      if (canonicalUrl === currentUrl) {
        return;
      }
      window.location.replace(canonicalUrl);
    })();
  }, [currentUrl]);

  useEffect(() => {
    const urlParts = currentUrl.split("/").filter(Boolean);
    const currentLanguage = urlParts[0];
    if (currentLanguage === "ar") setSpinner(false);
    else setSpinner(true);
  }, [currentUrl]);

  return spinner ? (
    <div
      style={{
        display: "flex",
        top: 0,
        bottom: 0,
        zIndex: 6500,
        backdropFilter: "blur(2px)",
        right: 0,
        backgroundColor: "#ffffff1a",
        left: 0,
        position: "fixed",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <BeatLoader
        color={"#AE9971"}
        loading={true}
        size={15}
        speedMultiplier={1}
      />
    </div>
  ) : (
    <Routing />
  );
}

export default Index;
