import { put, takeLatest, call } from "redux-saga/effects";
import { joinUs } from "../../network/joinUs";
import actions from "../../actions";
import { uploadFile, createFile } from "../../network/file";
const { JOINUS_REQ, joinusReqDone } = actions;

//joinUs

function* performjoinUs(action) {
  const fileIds = [];

  try {
    let data = action.payload;
    const profileImg = data?.profileImg;
    const idImg = data?.idImg;
    const passportImg = data?.passportImg;
    const license = data?.license;
    const cv = data?.cv;

    if (data?.profileImg && data?.profileImg?.length > 0) {
      var formData = new FormData();
      formData.append("fileKey", "joinUs-profileImg");
      profileImg.map((file) => {
        formData.append("files", file);
      });
      let responseData = yield call(uploadFile, formData);
      let payload = [];
      responseData?.data?.files.map((file, index) => {
        payload.push({
          uuid: file.filename || null,
          extension: file.extension,
          title: { ar: file.originalname, en: file.originalname },
          ...file,
          link: "profileImg",
        });
      });
      const filePayload = yield call(createFile, payload);
      filePayload?.data?.files?.map((files) => fileIds?.push(files.id));
    }

    if (data?.passportImg && data?.passportImg?.length > 0) {
      var formData = new FormData();
      formData.append("fileKey", "joinUs-passportImg");
      passportImg.map((file) => {
        formData.append("files", file);
      });

      let responseData = yield call(uploadFile, formData);
      let payload = [];
      responseData?.data?.files.map((file, index) => {
        payload.push({
          uuid: file.filename || null,
          title: { ar: file.originalname, en: file.originalname },
          extension: file.extension,
          ...file,
          link: "passportImg",
        });
      });

      const filePayload = yield call(createFile, payload);
      filePayload?.data?.files?.map((files) => fileIds?.push(files.id));
    }

    if (data?.idImg && data?.idImg?.length > 0) {
      var formData = new FormData();
      formData.append("fileKey", "joinUs-idImg");
      idImg.map((file) => {
        formData.append("files", file);
      });
      let responseData = yield call(uploadFile, formData);
      let payload = [];
      responseData?.data?.files.map((file, index) => {
        payload.push({
          uuid: file.filename || null,
          extension: file.extension,
          title: { ar: file.originalname, en: file.originalname },
          ...file,
          link: "idImg",
        });
      });
      const filePayload = yield call(createFile, payload);
      filePayload?.data?.files?.map((files) => fileIds?.push(files.id));
    }

    if (data?.license && data?.license?.length > 0) {
      var formData = new FormData();
      formData.append("fileKey", "joinUs-license");
      license.map((file) => {
        formData.append("files", file);
      });
      let responseData = yield call(uploadFile, formData);
      let payload = [];
      responseData?.data?.files.map((file, index) => {
        payload.push({
          uuid: file.filename || null,
          extension: file.extension,
          title: { ar: file.originalname, en: file.originalname },
          ...file,
          link: "license",
        });
      });
      const filePayload = yield call(createFile, payload);
      filePayload?.data?.files?.map((files) => fileIds?.push(files.id));
    }

    if (data?.cv && data?.cv?.length > 0) {
      var formData = new FormData();
      formData.append("fileKey", "joinUs-cv");
      cv.map((file) => {
        formData.append("files", file);
      });
      let responseData = yield call(uploadFile, formData);
      let payload = [];
      responseData?.data?.files.map((file, index) => {
        payload.push({
          uuid: file.filename || null,
          extension: file.extension,
          title: { ar: file.originalname, en: file.originalname },
          ...file,
          link: "cv",
        });
      });
      const filePayload = yield call(createFile, payload);
      filePayload?.data?.files?.map((files) => fileIds?.push(files.id));
    }

    // joinUs
    const result = yield call(joinUs, {
      serviceId: 1,
      name: data?.name,
      email: data?.email,
      phoneNumber: data?.phoneNumber,
      birthDate: data?.birthDate,
      emirate: data?.emirate,
      ageCategory: data?.ageCategory,
      qualification: data?.qualification,
      work: data?.work,
      experience: data?.experience,
      position: data?.position,
      employer: data?.employer,
      type: data?.type,
      fileIds: fileIds,
    });
    if (result) {
      yield put(joinusReqDone({ data: result.data }));
    } else {
      yield put(joinusReqDone({ data: [] }));
    }
  } catch (e) {
    yield put(joinusReqDone({ data: [] }));
    return;
  }
}

export function* watchSubmitCareer() {
  yield takeLatest(JOINUS_REQ, performjoinUs);
}
