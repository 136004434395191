export const themeList = [
  {
    id: 1,
    name: "default Theme",
    isActive: true,
    createdAt: "2023-03-10T13:41:49.211Z",
    updatedAt: "2023-03-10T13:41:49.211Z",
    elements: {
      type: "light",
      direction: "rtl",
      fontFamily: {
        ar: "Montserrat",
      },
      fontWeight: { s: 300, sm: 400, md: 500, lg: 600, xl: 700 },
      fontSize: {
        xl: 64,
        title: 34,
        lg: 20,
        m: 18,
        s: 16,
        sm: 14,
        xs: 12,
      },
      colors: {
        black: "#2E2E2E",
        title: "#313131",
        golden: "#AE9971",
        gold: "#BE912D",
        gray: "#838383",
        border: "#A6A6A6",
        breadCrumb: "#4F4D4D",
        graySummary: "#676767",
        lightBlue: "#E1F1FF",
        white: "#FFFFFF",
        pagination: "#E4E4E4",
        blue: "#6BC4E8",
        contactTitle: "#2D2D2D",
        borderTextField: "#DDDDDD",
        typeText: "#797979",
        labelBox: "#F6F4F1",
        labelFile: "#3D3D3D",
        typeNumber: "#62728A",
        remove: "#FA8A8A",
        tableHead: "#F2F2F2",
      },
    },
  },
];
