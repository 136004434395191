import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import crud from "./groups/crud";
import files from "./groups/file";
import joinUs from "./groups/joinUs";
import theme_reducer from "./theme";
import filterPosts from "./groups/filterPosts";
import contactUs from "./groups/contactUs";
const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    theme_reducer,
    crud,
    files,
    joinUs,
    filterPosts,
    contactUs,
  });

export default rootReducer;
