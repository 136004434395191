import crud from "./groups/crudActions";
import files from "./groups/file";
import theme from "./groups/theme";
import joinUs from "./groups/joinUs";
import filterPosts from "./groups/filterPosts";
import contactUs from "./groups/contactUs";
export default {
  ...theme,
  ...crud,
  ...files,
  ...joinUs,
  ...filterPosts,
  ...contactUs,
};
